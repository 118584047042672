import styles from './gamePage.module.scss';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const GamePage = () => {
  // const [gameStatus, setGameStatus] = useState('started');
  // const finishGame = () => {
  //   setGameStatus('finished');
  // };
  // const location = useLocation();
  //
  // const navigate = useNavigate();
  // if (!location.state || !location.state.from || location.state.from !== 'welcome') {
  //   navigate('/');
  // }
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        color: 'white',
        height: '100%',
        position: 'relative',
      }}
    >
      <Helmet>
        <script src="/breacklog/app.js" type="text/javascript" />
        <link rel="stylesheet" href="/breacklog/app.css"></link>
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: `<div id="game-container"></div>` }} />
    </div>
  );
};

export default GamePage;
