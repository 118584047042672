import styles from './captchaImage.module.scss';

import { useEffect, useRef, useState } from 'react';

const CaptchaImage = ({ url, isTrue = false, handleClickImage }) => {
  const [checked, setChecked] = useState(false);
  const checkboxRef = useRef(null);
  const handleCLick = (e) => {
    setChecked(!checked);

    handleClickImage(isTrue, !checked);
  };
  useEffect(() => {
    checkboxRef.current.classList.toggle('rc-imageselect-tileselected', checked);
  }, [checked]);

  return (
    <>
      <div className="rc-image-tile-target" ref={checkboxRef} onClick={handleCLick}>
        <div className="rc-image-tile-wrapper">
          <img className={styles.image} src={url} alt="" />
          <div className="rc-image-tile-overlay"></div>
        </div>
        <div className="rc-imageselect-checkbox"></div>
      </div>
    </>
  );
};

export default CaptchaImage;
