import styles from './captcha.module.scss';
import { useEffect, useRef, useState } from 'react';
import CaptchaPopup from 'components/captchaPopup/captchaPopup.component';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { useNavigate } from 'react-router-dom';
const Captcha = ({ setVerified, verified }) => {
  const inputRef = useRef(null);

  const onClick = (e) => {
    inputRef.current.checked = !inputRef.current.checked;
    setShowCaptcha(!showCaptcha);
  };
  const setValid = (valid) => {
    if (valid) {
      inputRef.current.checked = true;
      inputRef.current.className = styles.valid;
      setShowCaptcha(false);

      setTimeout(() => {
        setVerified(true);
        // navigate('/game', { state: { fromRobot: true } });
      }, 3000);
    }
  };
  useEffect(() => {
    // if (verified) {
    //   setShowCaptcha(false);
    // }
  }, []);
  const [showCaptcha, setShowCaptcha] = useState(false);
  return (
    <Popover
      isOpen={showCaptcha}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="blue"
          arrowSize={10}
          arrowStyle={{ opacity: 0.7 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <CaptchaPopup setValid={setValid} />
        </ArrowContainer>
      )}
      // positions={['top', 'left']} // if you'd like, you can limit the positions
      padding={10}
    >
      <div className={styles.captcha}>
        <div className={styles.spinner}>
          <label>
            <input type="checkbox" id="Check" onClick={onClick} onChange={onClick} ref={inputRef} />
            <span className={styles.checkmark}>
              <span>&nbsp;</span>
            </span>
          </label>
        </div>
        <label htmlFor="Check" className={styles.text}>
          I am not a robot
        </label>
        <div className={styles.logo}>
          <img src="https://forum.nox.tv/core/index.php?media/9-recaptcha-png/" />
          <p>reCAPTCHA</p>
          <small>Privacy - Terms</small>
        </div>
      </div>
    </Popover>
  );
};

export default Captcha;
