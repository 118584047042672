import styles from './mainPage.module.scss';
import Welcome from '../../components/welcome/welcome.component';

const MainPage = () => {
  return (
    <>
      <div className="container d-flex">
        <div className="mt-4 p-5  rounded card text-center m-auto">
          <div className="card-content">
            <Welcome />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
