import styles from './robotPage.module.scss';
import Captcha from 'components/captcha/captcha.component';

const RobotPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        // background: 'red',
        height: '90%',
      }}
    >
      <div>
        <Captcha />
      </div>
    </div>
  );
};

export default RobotPage;
