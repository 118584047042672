import styles from './welcome.module.scss';
import { Link } from 'react-router-dom';
import Captcha from 'components/captcha/captcha.component';
import { useState } from 'react';
import logo from '../../logo-blue.svg';
const Welcome = () => {
  const [verified, setVerified] = useState(false);
  return (
    <>
      <img src={logo} style={{ maxWidth: '200px' }} />
      <h2>Բարի գալուստ Վօլօ քվեստ!</h2>

      <div>
        {verified ? (
          <>
            <p>
              Իսկ հիմա փորձիր լուծել այս խնդիրը, որը հաղթահարելու դեպքում արդեն կարող ես գրանցվել:
              <br />
              Դու պետք գտնես ապակոդավորելու հերթականությունը:
              <br />
              Քեզ հաջողություն! 🥰
            </p>
            <Link to="/game" state={{ from: 'welcome' }}>
              <button className="btn btn-primary">Սկսենք խաղը</button>
            </Link>
          </>
        ) : (
          <>
            <p>Շնորհավոր քո 256-րդ օրը սիրելի Վօլօ մարդ։</p>
            <p>
              Եթե ուզում ես այսօրվա տոնի մասնակիցը լինել, ապա գրանցվիր ժամը 18:00 Վօլօ գլխամասում
              կայանալիք քվեստին
            </p>
            <p>Նախքան քո անունը գրանցելը մի քիչ մարզվենք քվեստից առաջ</p>
            <p>Նախ համոզվենք, որ դու ռոբոտ չես ...</p>
            <Captcha setVerified={setVerified} verified={verified} />
          </>
        )}
      </div>
    </>
  );
};

export default Welcome;
