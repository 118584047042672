import styles from './captchaFooter.module.scss';

const CaptchaFooter = ({ handleVerifyButtonClick, shoErrorText }) => {
  return (
    <div className="rc-footer">
      <div className="rc-separator"></div>
      <div className="rc-controls">
        <div className="primary-controls d-flex align-items-center">
          {/*<div className="rc-buttons">*/}
          {/*  <div className="button-holder reload-button-holder">*/}
          {/*    <button*/}
          {/*      className="rc-button goog-inline-block rc-button-reload"*/}
          {/*      title="Get a new challenge"*/}
          {/*      value=""*/}
          {/*      id="recaptcha-reload-button"*/}
          {/*      tabIndex="3"*/}
          {/*    ></button>*/}
          {/*  </div>*/}
          {/*  /!* More buttons here... *!/*/}
          {/*</div>*/}

          <div className="rc-error-message flex-grow-1">
            {shoErrorText && (
              <span className="rc-error-message-text">Խնդրում ենք ընտրել միայն մերոնց )</span>
            )}
          </div>

          <div className="verify-button-holder ml-auto">
            <button
              className="rc-button-default goog-inline-block"
              title=""
              value=""
              id="recaptcha-verify-button"
              onClick={handleVerifyButtonClick}
            >
              Verify
            </button>
          </div>
        </div>
        <div className="rc-challenge-help" style={{ display: 'none' }} tabIndex="0"></div>
      </div>
    </div>
  );
};

export default CaptchaFooter;
