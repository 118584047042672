import styles from './winPage.module.scss';

const WinPage = () => {
  return (
    <>
      <a href="https://forms.gle/gAhHi2xbZkAdXCb5A" className="btn btn-primary">
        You are win - lets register
      </a>
    </>
  );
};

export default WinPage;
