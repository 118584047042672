import styles from './captchaPopup.module.scss';

import armen from './images/1.png';
import armenangular from './images/armenAngular.png';
import arsen from './images/arsen.png';
import dartVader from './images/dart-vader.jpg';
import elon from './images/elon.jpg';
import haykm from './images/haykm.jpg';
import john from './images/john.jpg';
// import kitness from './images/kitness.png';
import lusine from './images/lusine.png';
import mickey from './images/mickey.jpg';
import mona from './images/mona.jpg';
import nelli from './images/nelli.png';
import nikol from './images/nikol.jpg';
import stella from './images/Stella.png';
import suro from './images/suro.png';
import tony from './images/tony.jpg';
import vahan from './images/vahan.png';

import CaptchaImage from 'components/captchaImage/captchaImage.component';
import CaptchaFooter from 'components/captchaFooter/captchaFooter.component';
import { useEffect, useState } from 'react';
const captchaImages = [
  { src: armen, isTrue: true },
  { src: armenangular, isTrue: true },
  { src: arsen, isTrue: true },
  { src: dartVader, isTrue: false },
  { src: elon, isTrue: false },
  { src: haykm, isTrue: false },
  { src: john, isTrue: false },
  // { src: kitness, isTrue: false },
  { src: lusine, isTrue: true },
  { src: mickey, isTrue: false },
  { src: mona, isTrue: false },
  { src: nelli, isTrue: true },
  { src: nikol, isTrue: false },
  { src: stella, isTrue: true },
  { src: suro, isTrue: true },
  { src: tony, isTrue: false },
  { src: vahan, isTrue: true },
];
const shuffle = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

const rightAnswersLength = captchaImages.filter((image) => image.isTrue).length;
const CaptchaPopup = ({ setValid }) => {
  const divStyle = {
    // width: '35 2px',
    fontSize: '12px',
  };
  const [right, setRight] = useState(0);
  const [showErrorText, setShowErrorText] = useState(false);

  const [randomArray, setRandomArray] = useState([]);
  useEffect(() => {
    shuffle(captchaImages);
    setRandomArray(captchaImages);
  }, []);

  const handleVerifyButtonClick = (e) => {
    if (right === rightAnswersLength) {
      setValid(true);
      setShowErrorText(false);
    } else {
      setValid(false);
      setShowErrorText(true);
    }
  };
  const handleClickImage = (isTrue, checked) => {
    setShowErrorText(false);
    if (isTrue && checked) {
      setRight((prev) => prev + 1);
    }
    if (!isTrue && !checked) {
      setRight((prev) => prev + 1);
    }
    if (isTrue && !checked) {
      setRight((prev) => prev - 1);
    }
    if (!isTrue && checked) {
      setRight((prev) => prev - 1);
    }
  };
  return (
    <div className={styles.popup}>
      <div>
        <div style={divStyle}>
          <div id="rc-imageselect" aria-modal="true" role="dialog">
            <div className="rc-imageselect-response-field"></div>
            <span className="rc-imageselect-tabloop-begin" tabIndex="0"></span>
            <div className="rc-imageselect-payload">
              <div className="rc-imageselect-instructions">
                <div className="rc-imageselect-desc-wrapper">
                  <div className="rc-imageselect-desc-no-canonical">
                    Select all images with <strong style={{ fontSize: '28px' }}>our people</strong>
                    <span style={{ fontSize: '14px' }}>Click verify once there are none left.</span>
                  </div>
                </div>
                <div className="rc-imageselect-progress"></div>
              </div>
              <div className="rc-imageselect-challenge">
                <div className={styles.imagesWrapper}>
                  {randomArray.map((image, index) => (
                    <CaptchaImage
                      key={index}
                      url={image.src}
                      isTrue={image.isTrue}
                      handleClickImage={handleClickImage}
                    />
                  ))}
                </div>
              </div>
            </div>
            <CaptchaFooter
              handleVerifyButtonClick={handleVerifyButtonClick}
              shoErrorText={showErrorText}
            />
            <span className="rc-imageselect-tabloop-end" tabIndex="0"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptchaPopup;
