import './App.css';
import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/mainPage/mainPage.component';
import RobotPage from './pages/robotPage/robotPage.component';
import Layout from './components/layout/layout.component';
import GamePage from './pages/gamePage/gamePage.component';
import WinPage from 'pages/winPage/winPage.component';
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<MainPage />} />
        <Route path="/robot" element={<RobotPage />} />
        <Route path="/win" element={<WinPage />} />
        <Route path="/game" element={<GamePage />} />
      </Route>
    </Routes>
  );
}

export default App;
