import styles from './layout.module.scss';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className="layout-wrapper">
      <div className="layout-overlay"></div>
      <div className="container-fluid main">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
